import * as React from 'react';
import styled from 'styled-components';
import { useRef } from 'react';
import { PageLayout } from '@/components/page-layout';
import { ReviewsIntegrations } from '@/features/integrations/reviews-integrations';
import { FacebookIntegration } from './facebook';
import { GoogleAnalyticsIntegration } from '@/features/integrations/google-analytics';
import { KlavioIntegration } from '@/features/integrations/klavio';
import {
  IntegrationsContext,
  newIntegrationsContext,
} from '@/features/integrations/context';
import { InstagramIntegration } from '@/features/integrations/instagram';

export function IntegrationsPage() {
  const wrapperRef = useRef(null);
  const integrationsContext = newIntegrationsContext(true);
  return (
    <PageLayout
      hideBackButton={false}
      heading="Integrations"
      description="Connect Visually.io to 3rd party platforms"
    >
      <IntegrationsContext.Provider value={integrationsContext}>
        <Grid ref={wrapperRef}>
          <KlavioIntegration />
          <FacebookIntegration />
          <GoogleAnalyticsIntegration />
          <InstagramIntegration />
          <ReviewsIntegrations mode="toggle" wrapperRef={wrapperRef} />
        </Grid>
      </IntegrationsContext.Provider>
    </PageLayout>
  );
}

const Grid = styled.div`
  display: grid;
  width: 90rem;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.5rem;
`;
