import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { IntegrationTile } from '@/features/integrations/integration-tile';
import { AccountContext } from '@/features/account-context';
import {
  onOpenFBOAuthPopup,
  useStoreSettingsApi,
} from '@/features/integrations/facebook/api';
import { IntegrationsContext } from '@/features/integrations/context';

export function FacebookIntegration() {
  const [selected, setSelected] = useState(false);
  const c = useContext(AccountContext);
  const { encrypt } = useStoreSettingsApi();
  const { integrationsStatus, toggleFacebook } =
    useContext(IntegrationsContext);
  useEffect(() => {
    setSelected(integrationsStatus.facebook === `YES`);
  }, [integrationsStatus.facebook]);
  useEffect(() => {
    const bc = new BroadcastChannel(`meta_auth`);
    bc.onmessage = () => {
      setSelected(true);
    };
    return () => bc.close();
  }, []);
  const meta = `"Meta"`;
  const handleChange = async () => {
    if (selected) {
      await toggleFacebook();
    } else {
      await onOpenFBOAuthPopup(encrypt, c?.account?.store?.alias)();
    }
  };
  return (
    <IntegrationTile
      key={meta}
      mode="toggle"
      allowToDisable
      onChange={handleChange}
      isSelected={selected}
      staticImage={
        <StaticImage
          height={80}
          placeholder="none"
          loading="eager"
          src="meta.png"
          alt="Facebook"
        />
      }
    >
      Connect to {meta} for a complete holistic experience for visitors coming
      from campaigns running on {meta}
    </IntegrationTile>
  );
}
