import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { IntegrationTile } from '@/features/integrations/integration-tile';
import { IntegrationsContext } from '@/features/integrations/context';

export const GoogleAnalyticsIntegration = () => {
  const { integrationsStatus, toggleGoogleAnalytics } =
    useContext(IntegrationsContext);
  const key = `googlea`;
  return (
    <IntegrationTile
      key={key}
      mode="toggle"
      allowToDisable
      onChange={toggleGoogleAnalytics}
      isSelected={
        integrationsStatus.googleAnalytics === `YES` ||
        integrationsStatus.googleAnalytics === `UNSET`
      }
      staticImage={
        <div style={{ margin: `1.45rem 0px 2rem 2rem` }}>
          <StaticImage
            height={40}
            placeholder="none"
            loading="eager"
            src="Logo_Google_Analytics.png"
            alt="google analytics"
          />
        </div>
      }
    >
      <p>
        Share events from experiences and experiments that
        <br /> run to Google Analytics for further tracking
      </p>
    </IntegrationTile>
  );
};
